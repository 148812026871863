@tailwind base;
@tailwind components;
@tailwind utilities;

idk-div {
  display: block;
  margin: 0;
  padding: 0;
}

idk-p {
  display: block;
  margin: 0;
  padding: 0;
}

idk-span {
  display: inline;
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
}

.Idukki-App,
.react-tiny-popover-container,
.idukki-modal-portal {
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  position: relative;
  .heading_main_container {
    font-size: 16px;
    @media only screen and (max-width: 575.98px) {
      font-size: 14px;
    }
  }

  #idk_modal_container {
    font-family: inherit;
    background-color: white;
  }

  a {
    text-decoration: inherit;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    top: 0;
    z-index: 1100;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring idk-div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(82, 80, 80);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: grey transparent transparent transparent;
  }

  .lds-ring idk-div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring idk-div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring idk-div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .truncate {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  .carousel-multi-container {
    padding: 0;
  }

  .pulsate {
    background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
    background-size: 400% 400%;
    -webkit-animation: Gradient 2.25s ease infinite;
    -moz-animation: Gradient 2.25s ease infinite;
    animation: Gradient 2.25s ease infinite;
  }

  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .carousel-multi-container {
    padding: 0;
    height: 100%;
  }


  .card-text-container {
    position: absolute;
    bottom: 0;
    height: 65px;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 15.49%, rgba(0, 0, 0, 0) 57.56%);
    color: white;
    padding: 5px;
    display: flex;
    align-self: end;
  }

  .card-text-container-inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    padding: 5px;
  }

  .loader-container {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0000008a;
    height: 100vh;
    margin: 0;
    padding: 0;
    top: 0;
    z-index: 1100;
  }

  * {
    scroll-behavior: smooth;
    font-family: inherit;
  }

  .react-tiny-popover-container {
    z-index: 1000;
  }
}

.main_video_container {
  width: 350px;
  height: 100vh;
  margin: auto;
}
.main_video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.video-questions-container {
  bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.video_option_list {
  margin: 0px 16px 16px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-option {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0px 12px 0px 16px;
  border-radius: 44px;
  box-sizing: border-box;
  color: white;
  transition: opacity 0.3s ease 0s;
  margin: 5px;
  justify-content: normal;
  width: 100%;
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 50px;
  height: fit-content;
  gap: 16px;
  &:hover {
    opacity: 0.8;
  }
}
.video-question {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: bold;
  text-shadow: rgb(9, 10, 11) 0px 0px 6px;
  margin: 8px 16px 16px;
  text-align: center;
}

.video-questions-container {
  opacity: 0;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.video-option.selected {
  animation: selectedAnimation 0.5s ease-in-out;
}

.video_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10; /* Adjust the alpha value for transparency */
}

.swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

@keyframes selectedAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.scroll-trigger.animate--slide-in:has(idk-div) {
  opacity: initial;
}